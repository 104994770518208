export enum CategoryName {
  Quotas = "Quotas",
  Features = "Features",
  TeamManagement = "Team Management",
  Support = "Support",
}

export enum FeatureType {
  Gif = "Gif",
  Info = "Info",
}

export type FeatureDescription = {
  name: FeatureName;
  type: FeatureType;
  description?: string;
};

export type FeatureValue = {
  value: string | boolean;
};

export enum TierName {
  Free = "Free",
  Basic = "Basic",
  Startup = "Startup",
  Enterprise = "Enterprise",
}

export type TierDetail = {
  name: TierName;
  basePrice: number;
  includedSeats: number;
  featureNameToValue: { [name in FeatureName]: string | boolean };
  ctaButtonType: "primary" | "secondary";
  ctaButtonText: string;
  ctaButtonLink: string;
};

export enum FeatureName {
  SessionCredits = "Session Credits",
  IncludedSeats = "Included Seats",
  SessionOverageRate = "Session Overage Rate",
  PublicSessionSharing = "Public Session Sharing",
  CustomDataExport = "Custom Data Export",
  EnhancedUserProperties = "Enhanced User Properties",
  Comments = "Session / Error Commenting",
  DataRetention = "Data Retention",
  SingleSignOn = "Single Sign-On",
  RoleBasedAccessControl = "Role-Based Access Control",
  TwoFactorAuth = "Two-Factor Authentication",
  EmailSupport = "Email",
  IntercomSupport = "Intercom",
  SlackConnectSupport = "Slack Connect",
  TwentyFourSevenSLA = "24x7 Support with SLAs",
}

export const TIER_TO_TIER_DETAILS: { [name in TierName]: TierDetail } = {
  [TierName.Free]: {
    name: TierName.Free,
    basePrice: 0,
    ctaButtonType: "primary",
    ctaButtonText: "Get Started",
    ctaButtonLink: "https://app.highlight.run?sign_up=1",
    includedSeats: 100,
    featureNameToValue: {
      [FeatureName.IncludedSeats]: "Unlimited",
      [FeatureName.SessionCredits]: "500",
      [FeatureName.SessionOverageRate]: "$5 / 1k sessions / month",
      [FeatureName.PublicSessionSharing]: false,
      [FeatureName.CustomDataExport]: false,
      [FeatureName.EnhancedUserProperties]: false,
      [FeatureName.Comments]: true,
      [FeatureName.DataRetention]: "1 month",
      [FeatureName.SingleSignOn]: false,
      [FeatureName.RoleBasedAccessControl]: false,
      [FeatureName.TwoFactorAuth]: false,
      [FeatureName.EmailSupport]: true,
      [FeatureName.IntercomSupport]: true,
      [FeatureName.SlackConnectSupport]: false,
      [FeatureName.TwentyFourSevenSLA]: false,
    },
  },
  [TierName.Basic]: {
    name: TierName.Basic,
    basePrice: 100,
    ctaButtonType: "secondary",
    ctaButtonText: "Start Trial",
    ctaButtonLink: "https://app.highlight.run?sign_up=1",
    includedSeats: 2,
    featureNameToValue: {
      [FeatureName.IncludedSeats]: "2",
      [FeatureName.SessionCredits]: "10,000",
      [FeatureName.SessionOverageRate]: "$5 / 1k sessions / month",
      [FeatureName.PublicSessionSharing]: false,
      [FeatureName.CustomDataExport]: false,
      [FeatureName.EnhancedUserProperties]: false,
      [FeatureName.Comments]: true,
      [FeatureName.DataRetention]: "Custom",
      [FeatureName.SingleSignOn]: false,
      [FeatureName.RoleBasedAccessControl]: false,
      [FeatureName.TwoFactorAuth]: false,
      [FeatureName.EmailSupport]: true,
      [FeatureName.IntercomSupport]: true,
      [FeatureName.SlackConnectSupport]: false,
      [FeatureName.TwentyFourSevenSLA]: false,
    },
  },
  [TierName.Startup]: {
    name: TierName.Startup,
    basePrice: 300,
    ctaButtonType: "secondary",
    ctaButtonText: "Start Trial",
    ctaButtonLink: "https://app.highlight.run?sign_up=1",
    includedSeats: 8,
    featureNameToValue: {
      [FeatureName.IncludedSeats]: "8",
      [FeatureName.SessionCredits]: "80,000",
      [FeatureName.SessionOverageRate]: "$5 / 1k sessions / month",
      [FeatureName.PublicSessionSharing]: true,
      [FeatureName.CustomDataExport]: false,
      [FeatureName.EnhancedUserProperties]: true,
      [FeatureName.Comments]: true,
      [FeatureName.DataRetention]: "Custom",
      [FeatureName.SingleSignOn]: true,
      [FeatureName.RoleBasedAccessControl]: true,
      [FeatureName.TwoFactorAuth]: true,
      [FeatureName.EmailSupport]: true,
      [FeatureName.IntercomSupport]: true,
      [FeatureName.SlackConnectSupport]: true,
      [FeatureName.TwentyFourSevenSLA]: false,
    },
  },
  [TierName.Enterprise]: {
    name: TierName.Enterprise,
    basePrice: 1000,
    ctaButtonType: "secondary",
    ctaButtonText: "Schedule Demo",
    ctaButtonLink: "https://calendly.com/jaykhatri/highlight-demo-call?ref=null",
    includedSeats: 12,
    featureNameToValue: {
      [FeatureName.IncludedSeats]: "12",
      [FeatureName.SessionCredits]: "200,000+",
      [FeatureName.SessionOverageRate]: "$5 / 1k sessions / month",
      [FeatureName.PublicSessionSharing]: true,
      [FeatureName.CustomDataExport]: true,
      [FeatureName.EnhancedUserProperties]: true,
      [FeatureName.Comments]: true,
      [FeatureName.DataRetention]: "Custom",
      [FeatureName.SingleSignOn]: true,
      [FeatureName.RoleBasedAccessControl]: true,
      [FeatureName.TwoFactorAuth]: true,
      [FeatureName.EmailSupport]: true,
      [FeatureName.IntercomSupport]: true,
      [FeatureName.SlackConnectSupport]: true,
      [FeatureName.TwentyFourSevenSLA]: true,
    },
  },
};

export const CATEGORY_TO_FEATURE: {
  [name in CategoryName]: FeatureDescription[];
} = {
  [CategoryName.Quotas]: [
    {
      name: FeatureName.SessionCredits,
      type: FeatureType.Info,
      description:
        "The number of sessions you can access across all of your projects, measured monthly.",
    },
    {
      name: FeatureName.IncludedSeats,
      type: FeatureType.Info,
      description:
        "The number of free members in your workspace for your current billing plan.",
    },
    {
      name: FeatureName.SessionOverageRate,
      type: FeatureType.Info,
      description:
        "Amount billed for sessions above your monthly 'Session Credits'.",
    },
    {
      name: FeatureName.DataRetention,
      type: FeatureType.Info,
      description:
        "The amount of time that data (sessions, errors, metrics) is guarenteed to be retained in Highlight.",
    },
  ],
  [CategoryName.Features]: [
    {
      name: FeatureName.PublicSessionSharing,
      type: FeatureType.Info,
      description:
        "The ability to share sessions outside of your organization.",
    },
    {
      name: FeatureName.CustomDataExport,
      type: FeatureType.Info,
      description:
        "The ability to export your Highlight data to a cold storage bucket (S3, GCS, etc..) for further queries/processing.",
    },
    {
      name: FeatureName.EnhancedUserProperties,
      type: FeatureType.Info,
      description:
        "The ability to export your Highlight data to a cold storage bucket (S3, GCS, etc..) for further queries/processing.",
    },
    {
      name: FeatureName.Comments,
      type: FeatureType.Info,
      description:
        "The ability to comment and tag your team to bring attention to issues on sessions / errors.",
    },
  ],
  [CategoryName.TeamManagement]: [
    {
      name: FeatureName.RoleBasedAccessControl,
      type: FeatureType.Info,
      description: "The ability to edit read/write access to resources in Highlight at a user level."
    },
    {
      name: FeatureName.SingleSignOn,
      type: FeatureType.Info,
    },
    {
      name: FeatureName.TwoFactorAuth,
      type: FeatureType.Info,
    },
  ],
  [CategoryName.Support]: [
    {
      name: FeatureName.EmailSupport,
      type: FeatureType.Info,
      description: "2-day turnaround support over email."
    },
    {
      name: FeatureName.IntercomSupport,
      type: FeatureType.Info,
      description: "1-day turnaround support over intercom chat."
    },
    {
      name: FeatureName.SlackConnectSupport,
      type: FeatureType.Info,
      description: "Direct commmunication via a shared slack channel (a.k.a. Slack Connect)."
    },
    {
      name: FeatureName.TwentyFourSevenSLA,
      type: FeatureType.Info,
    },
  ],
};
