import * as React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { styled } from "@stitches/react";

const StyledContent = styled(Tooltip.Content, {
  borderRadius: 3,
  padding: "5px 10px",
  fontSize: 14,
  backgroundColor: "black",
  color: "white",
  width: 250,
});

const StyledArrow = styled(Tooltip.Arrow, {
  fill: "gainsboro",
});

const InfoIcon = ({ hoverText }: { hoverText?: string }) => {
  return (
    <Tooltip.Root delayDuration={0}>
      <Tooltip.Trigger style={{border: "none", backgroundColor: "white"}}>
        <div style={{ marginTop: -4, marginLeft: 0 }}>
          <svg width="1.3em" height="1.3em" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 13v2"
            />
            <circle cx={12} cy={9} r={1} fill="currentColor" />
            <circle
              cx={12}
              cy={12}
              r={7.25}
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
            />
          </svg>
        </div>
      </Tooltip.Trigger>
      <StyledContent side="top">
        <StyledArrow style={{fill: "black"}} />
        {hoverText}
      </StyledContent>
    </Tooltip.Root>
  );
};

export default InfoIcon;
