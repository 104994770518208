import React from "react";
import { styled } from "@stitches/react";
import * as SwitchPrimitive from "@radix-ui/react-switch";

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: "unset",
  width: 32,
  height: 16,
  backgroundColor: "white",
  borderRadius: "9999px",
  border: "1px solid var(--border-color)",
  position: "relative",
  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  '&[data-state="checked"]': { backgroundColor: "#5dcf1f" },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: "block",
  width: 12,
  height: 12,
  backgroundColor: "white",
  borderRadius: "9999px",
  boxShadow: `2px 4px 6px rgb(0 0 0 / 20%)`,
  transition: "transform 100ms",
  transform: "translateX(2px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(17px)" },
});

// Exports
const Switch = StyledSwitch;
const SwitchThumb = StyledThumb;

const Flex = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const Label = styled("label", {
  color: "var(--color-text-primary)",
  fontSize: 12,
  userSelect: "none",
  fontWeight: 400,
});

type SwitchProps = SwitchPrimitive.SwitchProps & {
  label: string;
  booleanValue: boolean;
};

const SwitchComponent = ({ label, booleanValue, ...props }: SwitchProps) => (
  <form>
    <Flex>
      <Label
        htmlFor={`s1-${label}`}
        css={{
          paddingRight: 15,
          color: booleanValue
            ? "var(--color-text-primary)"
            : "var(--color-text-secondary)",
        }}
      >
        {label}
      </Label>
      <Switch id={`s1-${label}`} {...props}>
        <SwitchThumb />
      </Switch>
    </Flex>
  </form>
);

export default SwitchComponent;
