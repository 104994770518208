import React, { useState } from "react";
import "./App.css";
import Switch from "./components/Switch/Switch";
import Select from "react-select";
import {
  TIER_TO_TIER_DETAILS,
  CATEGORY_TO_FEATURE,
  CategoryName,
  FeatureDescription,
  FeatureType,
  TierName,
  TierDetail,
  FeatureName,
} from "./Data";
import InfoIcon from "./icons/InfoIcon";
/**
 * Self hosting costs 50% more.
 */

function App() {
  const [showAnnualPricing, setShowAnnualPricing] = useState(false);
  const [selfHost, setSelfHost] = useState(false);
  const [numberOfSeats, setNumberOfSeats] = useState(2);
  const [dataRetentionSate, setDataRetentionState] = useState<{
    label: string;
    value: number;
  } | null>({ label: "3 month retention", value: 3 });

  const TierToTierDetails = TIER_TO_TIER_DETAILS;
  const CategoryToFeature = CATEGORY_TO_FEATURE;

  return (
    <div className={"prod-mode"}>
      <main id="calculator-container">
        <div className="config-container-wrap">
          <div className="config-container-section">
            <div className="config-container">
              <div className="controls-container">
                <div className="details-label">Details</div>
                <div className="annual-discount-toggle">
                  <Switch
                    label="Annual Discount"
                    defaultChecked={showAnnualPricing}
                    booleanValue={showAnnualPricing}
                    onCheckedChange={(e) => {
                      setShowAnnualPricing(e);
                    }}
                  />
                </div>
                <div className="annual-discount-toggle self-host-toggle">
                  <Switch
                    label="Self Host"
                    defaultChecked={selfHost}
                    booleanValue={selfHost}
                    onCheckedChange={(e) => {
                      setSelfHost(e);
                    }}
                  />
                </div>
                <div className="control">
                  <label>
                    Seat Count{" "}
                    <input
                      type="text"
                      value={numberOfSeats}
                      onChange={(e) => {
                        setNumberOfSeats(parseInt(e.target.value, 10) || 0);
                      }}
                      min={1}
                    />
                  </label>
                </div>
                <div className="control">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 12,
                      }),
                      control: (base) => ({
                        ...base,
                        borderColor: "#e6e8ea",
                        borderRadius: 8,
                      }),
                      valueContainer: (base) => ({ ...base, fontSize: 12 }),
                    }}
                    options={[
                      { value: 3, label: "3 month retention" },
                      { value: 6, label: "6 month retention" },
                      { value: 12, label: "12 month retention" },
                      { value: 20, label: "Unlimited retention" },
                    ]}
                    onChange={(v) => setDataRetentionState(v)}
                    value={dataRetentionSate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="controls-column c-1">
          <div className="c-1-inner">
            {Object.keys(CategoryToFeature).map((categoryName) => {
              const featuresForCategory =
                CategoryToFeature[categoryName as CategoryName];
              return (
                <div key={categoryName}>
                  <React.Fragment key={categoryName}>
                    <div className="title-container b-t">
                      <h3 className="tier-name">{categoryName}</h3>
                    </div>
                    <div className="features-section">
                      {featuresForCategory.map(
                        (feature: FeatureDescription) => (
                          <div
                            key={feature.name}
                            className="feature category-name"
                          >
                            {feature.type === FeatureType.Gif ? (
                              // eslint-disable-next-line react/jsx-no-target-blank
                              <a href={"ehhh"} target="_blank">
                                {feature.name}
                              </a>
                            ) : (
                              feature.name
                            )}
                            {feature.type === FeatureType.Info && (
                              <>
                                {feature.description && (
                                  <InfoIcon hoverText={feature.description} />
                                )}
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </React.Fragment>
                </div>
              );
            })}
          </div>
        </div>
        {Object.keys(TierToTierDetails).map(
          (tierName: string, index: number) => {
            const tier = TierToTierDetails[tierName as TierName];
            return (
              <div className={`tier c-${index + 2}`} key={tier.name}>
                <Tier
                  retentionMonths={dataRetentionSate?.value ?? 3}
                  tier={tier}
                  showAnnualPricing={showAnnualPricing}
                  numberOfSeats={numberOfSeats}
                  selfHost={selfHost}
                  categoryToFeature={CategoryToFeature}
                />
              </div>
            );
          }
        )}
      </main>
    </div>
  );
}

export default App;

interface ITierProps {
  tier: TierDetail;
  showAnnualPricing: boolean;
  numberOfSeats: number;
  selfHost: boolean;
  retentionMonths: number;
  categoryToFeature: { [name in CategoryName]: FeatureDescription[] };
}

const Tier = ({
  tier: {
    featureNameToValue,
    name,
    basePrice,
    ctaButtonType,
    ctaButtonText,
    ctaButtonLink,
    includedSeats,
  },
  numberOfSeats,
  retentionMonths,
  showAnnualPricing,
  selfHost,
  categoryToFeature,
}: ITierProps) => {
  let seatPrice =
    numberOfSeats - includedSeats > 0
      ? (numberOfSeats - includedSeats) * 15
      : 0;
  let retentionMultiplier =
    retentionMonths <= 3
      ? 1
      : retentionMonths <= 6
      ? 1.5
      : retentionMonths <= 12
      ? 2
      : 3;
  if (selfHost) {
    basePrice = basePrice * 1.5;
  }
  if (showAnnualPricing) {
    basePrice = basePrice * 0.8;
  }
  basePrice = basePrice * retentionMultiplier;
  let totalPrice = basePrice + seatPrice;
  let internationalNumberFormat = new Intl.NumberFormat("en-US");

  return (
    <div>
      <div className="title-container">
        <h3 className="tier-name">{name}</h3>
      </div>

      <div className="tier-information-container">
        <div className="card-top-section">
          <div>
            <h2 className="tier-pricing-container">
              <span className="currency-symbol">$</span>
              <span className="tier-price">
                {internationalNumberFormat.format(totalPrice)}
                {name === TierName.Enterprise && "+"}
              </span>
              <span className="tier-cadence">/mo</span>
            </h2>
          </div>

          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className={`btn-cta-${ctaButtonType} start-trial-button`}
            href={ctaButtonLink}
            target="_blank"
          >
            {ctaButtonText}
          </a>
        </div>
        <div className="card-bottom-section">
          <div className="seats-pricing-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p style={{ fontSize: 13, margin: 0 }}>Base Price</p>
                <p style={{ fontSize: 10, margin: 0, color: "grey" }}>
                  billed {showAnnualPricing ? "annually" : "monthly"}{" "}
                </p>
              </div>
              <p style={{ fontSize: 14, fontWeight: 500 }}>
                ${internationalNumberFormat.format(basePrice)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p style={{ fontSize: 13, margin: 0 }}>Seat Pricing</p>
                <p style={{ fontSize: 10, margin: 0, color: "grey" }}>
                  billed monthly{" "}
                </p>
              </div>
              <p style={{ fontSize: 14, fontWeight: 500 }}>
                ${internationalNumberFormat.format(seatPrice)}
              </p>
            </div>
            {/* <p className="add-on">
            + ${subtotalForSeats.toLocaleString()} (${pricePerSeat} x{" "}
            {numberOfSeatsToCharge} seats)
          </p>
          <p className="add-on label">Billed monthly</p> */}
          </div>
        </div>
      </div>

      <div className="features-categories">
        {Object.keys(categoryToFeature).map((categoryName) => {
          const featuresForCategory =
            categoryToFeature[categoryName as CategoryName];
          const featureDetailsForCategory = featuresForCategory.map(
            (f) => featureNameToValue[f.name as FeatureName]
          );
          return (
            <div key={categoryName}>
              <div className="title-container b-t">
                <span className="tier-name empty">{categoryName}</span>
              </div>

              <div className="features-section">
                {featuresForCategory.map((feature, i: number) => (
                  <div key={feature.name} className="feature">
                    {typeof featureDetailsForCategory[i] === "boolean" &&
                    featureDetailsForCategory[i] ? (
                      <div className="check-icon">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 9"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4.076 8.823l-3.9-3.922a.606.606 0 010-.854l.848-.853a.598.598 0 01.849 0L4.5 5.836l5.627-5.66a.598.598 0 01.849 0l.848.854a.606.606 0 010 .854l-6.9 6.94a.598.598 0 01-.848 0z"></path>
                        </svg>
                      </div>
                    ) : typeof featureDetailsForCategory[i] === "boolean" &&
                      !featureDetailsForCategory[i] ? (
                      <span style={{ fontSize: 45, fontWeight: 100 }}>-</span>
                    ) : (
                      featureDetailsForCategory[i]
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className="features-categories-mobile">
        {Object.keys(categoryToFeature).map((categoryName) => {
          const featuresForCategory =
            categoryToFeature[categoryName as CategoryName];
          const featureDetailsForCategory = featuresForCategory.map(
            (f) => featureNameToValue[f.name as FeatureName]
          );
          return (
            <div key={categoryName}>
              <div className="title-container b-t">
                <span className="tier-name">{categoryName}</span>
              </div>

              <div className="features-section">
                {featuresForCategory.map((feature, i: number) => (
                  <div key={feature.name} className="feature">
                    <span className="feature-name">{feature.name}</span>
                    <span>
                      {typeof featureDetailsForCategory[i] === "boolean" ? (
                        <div className="check-icon">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 12 9"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M4.076 8.823l-3.9-3.922a.606.606 0 010-.854l.848-.853a.598.598 0 01.849 0L4.5 5.836l5.627-5.66a.598.598 0 01.849 0l.848.854a.606.606 0 010 .854l-6.9 6.94a.598.598 0 01-.848 0z"></path>
                          </svg>
                        </div>
                      ) : (
                        featureDetailsForCategory[i]
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
